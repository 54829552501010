<template>
  <div>
    <div class="main" v-title data-title="百川资管-首页">
    </div>
    <Header class="header" active="statementDetail" />
    <div class="blank"></div>
    <div class="content">
      <b-container class="statement">
        <h2>声明</h2>
        <div class="text">近期，我司（百川财富（北京）投资管理有限公司）发现有不法分子盗用我司名称，伪造“营业执照”等凭证，以网络平台兼职、刷单方式进行诈骗，造成了恶劣的社会影响。为维护我司的社会声誉及其他合法权益，特声明如下：</div>
        <div class="text">一、我司从未设立或者委托任何机构或个人从事“网络兼职、刷单”行为，亦从未发布、招聘过任何从事“网络兼职、刷单”的岗位。</div>
        <div class="text">二、请勿通过个人微信、支付宝、银行转账及现金等方式与声称为我公司工作人员的不法分子进行财物交易，或向其透露个人隐私、银行账户等敏感信息。</div>
        <div class="text">三、我司对于任何机构或个人冒用“百川财富（北京）投资管理有限公司”名义所开展的任何经营活动概不负责。请大家仔细甄别虚假信息，以免被不法分子利用而上当受骗，如接到相关的诈骗信息，或有财务损失，请立即向当地公安机关报案。</div>
        <div class="text">四、对任何冒用假借我司名义进行违法活动的机构及个人，一经发现，我司将保留依法追究其相关法律责任的权利。</div>
        <div class="text">我司是一家在中国基金业协会登记备案的阳光私募机构，主营业务为私募证券投资基金管理。一切涉及我司的信息，均请以公司及授权合作方的合法渠道公告事项为准</div>
        <div class="text">特此声明！</div>
        <div class="footer">
          <div class="text">百川财富（北京）投资管理有限公司</div>
          <div class="text">2022年10月24日</div>
        </div>
      </b-container>
    </div>
    <Footer class="footer" />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Index',
  components: {
    Header,
    Footer
  }
}
</script>
<style scoped>
.blank {
  height: 100px;
}
.statement {
  text-align: justify;
  padding: 40px 80px !important;
}
h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}
.text {
  text-indent: 2em;
  padding-top: 30px;
}
.statement .footer {
  padding-top: 40px;
  text-align: right;
}
</style>
